<template>
    <div class="lifeContent">
            <el-timeline :reverse="reverse" >
                <el-timeline-item v-for="(value,index) in activities" :key="index" :timestamp="value.dt" placement="top"  :class="[index%2==0?'animated fadeInLeftBig':'animated fadeInRightBig']">
                    <el-card>
                        <h4>{{value.content}}</h4>
                        <br/>
                        <video loop="loop" controls="controls" :src="value.videoUrl" v-if="value.videoUrl"></video>
                        <img v-if="value.imgUrl" :src="value.imgUrl" alt="">
                    </el-card>
                </el-timeline-item>
            </el-timeline>
    </div>
</template>

<script>
    export default {
        name: "lifeContent",
        data(){
            return{
                reverse:true,
                activities:[]
            }
        },
        methods:{
            async findLifeList(){
                const res = await this.$home.listList()
                this.activities = res.data
            }
        },
        created() {
            this.findLifeList()
            this.$store.commit("updateRotation",false)
            /* 隐藏左侧 */
            this.$store.commit("updateLeftShow",true)
        }
    }
</script>

<style scoped>

</style>
